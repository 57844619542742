import React, { ReactElement } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { darkBlue3 } from "site-ui/colors";
import { options, renderRichText } from "twill-contentful-text-render";
import { Section } from "site-ui/src/components/Section";
import { ICountry } from "../../models/interfaces";

export interface ICountryPorts {
  country: ICountry;
}

export function CountryPorts({ country }: ICountryPorts): ReactElement {
  if (!country.ports) {
    return null;
  }

  return (
    <Section
      heading={
        <Typography variant="h2">{`Ports and offices in ${country.name}`}</Typography>
      }
    >
      <Grid spacing={4} alignItems="stretch" container>
        {country.ports.map((port) => (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box
              minHeight="100%"
              border={1}
              borderColor={darkBlue3}
              p={{ xs: 2, sm: 2, md: 4 }}
              borderRadius={3}
            >
              <Typography variant="h4" gutterBottom>
                {port.title}
              </Typography>
              {renderRichText(port.address, options)}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Section>
  );
}
