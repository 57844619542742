import React, { ReactElement } from "react";
import Container from "@material-ui/core/Container";
import { Box, createStyles, withStyles } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useTranslation } from "twill-net-localization";

const StyledTab = withStyles((theme) =>
  createStyles({
    root: {
      textTransform: "none",
      fontSize: "16px",
      color: "main.primary",
    },
  })
)((props) => <Tab disableRipple {...props} />);

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export interface ICountryTabsProps {
  currentTab: number;
  changeTab: (e: React.ChangeEvent<{}>, tab: number) => void;
}

export function CountryTabs({
  currentTab,
  changeTab,
}: ICountryTabsProps): ReactElement {
  const { t } = useTranslation();

  return (
    <Box mt={2}>
      <Container>
        <Tabs
          value={currentTab}
          onChange={changeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="twill services"
        >
          <StyledTab
            label={t("common.logistics", "Logistics")}
            {...a11yProps(0)}
          />
          <StyledTab
            label={t("countryPage.pricingPayment", "Pricing & payment")}
            {...a11yProps(1)}
          />
          <StyledTab
            label={t("contryPage.localSolutions", "Local Solutions")}
            {...a11yProps(2)}
          />
        </Tabs>
      </Container>
    </Box>
  );
}
