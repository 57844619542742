import React, { ReactElement } from "react";
import { PageWrapper } from "site-ui/src/components/PageWrapper";
import { ICountry } from "../../models/interfaces";
import { CountryBreadcrumbs, CountryContent, CountryHero } from "..";

export interface ICountryContainerProps {
  country: ICountry;
  services?: any[];
  counters?: any[];
}

export function CountryContainer({
  country,
  services,
  counters,
}: ICountryContainerProps): ReactElement {
  return (
    <PageWrapper variant="empty">
      <CountryBreadcrumbs country={country} />
      <CountryHero country={country} />
      <CountryContent
        country={country}
        services={services}
        counters={counters}
      />
    </PageWrapper>
  );
}
