import React, { ReactElement } from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "twill-net-localization";
import { Accordion } from "site-ui/src/components";
import { options, renderRichText } from "twill-contentful-text-render";
import { Section } from "site-ui/src/components/Section";
import { ICountry } from "../../models/interfaces";

export interface ICountryPricingAndPaymentsProps {
  country: ICountry;
}

export function CountryPricingAndPayments({
  country,
}: ICountryPricingAndPaymentsProps): ReactElement {
  const { t } = useTranslation();

  if (!country.pricingAndPayment) {
    return null;
  }

  return (
    <Section
      heading={
        <Typography variant="h2">
          {t("common.pricingPayment", "Pricing and Payment")}
        </Typography>
      }
    >
      <Accordion
        questions={country.pricingAndPayment.map((item) => ({
          question: item.title,
          answer: renderRichText(item.text, options),
        }))}
      />
    </Section>
  );
}
