import React from "react";
import { graphql } from "gatsby";
import { CountryContainer } from "../../ui/components/CountryContainer/CountryContainer";

export default ({ data }) => {
  const { contentfulCountry: country, services, counters } = data;

  return (
    <CountryContainer
      country={country}
      services={services}
      counters={counters}
    />
  );
};

export const pageQuery = graphql`
  query CountryPageQuery($country_id: String!, $locale: String!) {
    services: contentfulBlockServices(
      contentful_id: { eq: "4AWMVz3QhT6i11qR0XUHaQ" }
      node_locale: { eq: $locale }
    ) {
      __typename
      ...ServicesBlockFields
    }
    counters: contentfulBlockCounters(
      contentful_id: { eq: "3VefIK2peW9D89pM3sch1n" }
      node_locale: { eq: $locale }
    ) {
      __typename
      ...CounterFields
    }
    locales: allLocale(filter: { language: { eq: $locale } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contentfulCountry(
      contentful_id: { eq: $country_id }
      node_locale: { eq: $locale }
    ) {
      name
      ports {
        title
        address {
          raw
        }
      }
      hero {
        __typename
        ...HeroBlockFields
      }
      shippingInfo {
        ...RichTextBlockFields
      }
      pricingAndPayment {
        ...RichTextBlockFields
      }
      localSolutions {
        ...RichTextBlockFields
      }
    }
  }
`;
