import React, { ReactElement } from "react";
import { Blocks } from "page-constructor/src/components/Blocks";
import { ICountry, ICountryHero } from "../../models/interfaces";

const defaultHeroBackground =
  "https://images.ctfassets.net/va7gqy6bsjah/5oRfB96WTp6U8Dzct3GDZD/f620773da545aa623f1a40fa3f305de3/1e59a9b0-8c28-4f6d-a3dc-d1abcb47309a__1_.jpg?w=1920";

function checkHeroBackground(heroBlock: ICountryHero): ICountryHero {
  if (heroBlock.backgroundImage) {
    return heroBlock;
  }
  const updatedHeroBlock = Object.assign({}, heroBlock);
  updatedHeroBlock.backgroundImage = {
    localFile: {
      publicURL: defaultHeroBackground,
    },
  };
  return updatedHeroBlock;
}

export interface ICountryHeroProps {
  country: ICountry;
}

export function CountryHero({ country }: ICountryHeroProps): ReactElement {
  return <Blocks blocks={[checkHeroBackground(country.hero)]} />;
}
