import React, { ReactElement } from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "twill-net-localization";
import { options, renderRichText } from "twill-contentful-text-render";
import { Accordion } from "site-ui/src/components";
import { Section } from "site-ui/src/components/Section";
import { ICountry } from "../../models/interfaces";

export interface ICountryShippingInfoProps {
  country: ICountry;
}

export function CountryShippingInfo({
  country,
}: ICountryShippingInfoProps): ReactElement {
  const { t } = useTranslation();

  if (!country.shippingInfo) {
    return null;
  }

  return (
    <Section
      heading={
        <Typography variant="h2">
          {t(
            "countryPage.relevantShippingInfo",
            "Relevant shipping information"
          )}
        </Typography>
      }
    >
      <Accordion
        questions={country.shippingInfo.map((item) => ({
          question: item.title,
          answer: item.text && renderRichText(item.text, options),
        }))}
      />
    </Section>
  );
}
