import React, { ReactElement, useContext } from "react";
import { DataContext, LocaleLink as Link } from "gatsby-theme-twill-website";
import { useTranslation } from "twill-net-localization";
import { Breadcrumbs } from "site-ui/src/components/Breadcrumbs";
import { ICountry } from "../../models/interfaces";
import { getSectionRoute } from "../../../../tools/ui/helpers/getSectionRoute";
import { Sections } from "../../../../tools/ui/constants";

export interface ICountryBreadcrumbsProps {
  country: ICountry;
}

export function CountryBreadcrumbs({
  country,
}: ICountryBreadcrumbsProps): ReactElement {
  const {
    pageContext: { locale },
  } = useContext(DataContext);
  const { t } = useTranslation();

  return (
    <Breadcrumbs
      items={[
        <Link to={`/${getSectionRoute(locale, Sections.CONTAINER_SHIPPING)}/`}>
          {t("common.shippingToHeader", "Container shipping to")}
        </Link>,
        country.name,
      ]}
    />
  );
}
