import React, { PropsWithChildren, ReactElement, useState } from "react";
import { Box } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Blocks } from "page-constructor/src/components/Blocks";
import {
  CountryShippingInfo,
  CountryTabs,
  CountryPorts,
  CountryPricingAndPayments,
  CountryLocalSolutions,
} from "..";
import { ICountry } from "../../models/interfaces";

interface ITabPanelProps {
  value: number;
  index: number;
  isAvailable: boolean;
}

function TabPanel(props: PropsWithChildren<ITabPanelProps>) {
  const { children, value, index, isAvailable, ...other } = props;

  let isHidden = value !== index || !isAvailable;

  return (
    <div
      role="tabpanel"
      hidden={isHidden}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export interface ICountryContentProps {
  country: ICountry;
  services?: any[];
  counters?: any[];
}

export function CountryContent({
  country,
  services,
  counters,
}: ICountryContentProps): ReactElement {
  const [currentTab, setCurrentTab] = useState(0);
  const changeTab = (event: React.ChangeEvent<{}>, newTab: number) => {
    setCurrentTab(newTab);
  };

  const isTab1Available = !!country.shippingInfo || !!country.ports;
  const isTab2Available = !!country.pricingAndPayment;
  const isTab3Available = !!country.localSolutions;

  return (
    <>
      <CountryTabs currentTab={currentTab} changeTab={changeTab} />
      <TabPanel value={currentTab} index={0} isAvailable={isTab1Available}>
        <CountryShippingInfo country={country} />
        <CountryPorts country={country} />
      </TabPanel>
      <TabPanel value={currentTab} index={1} isAvailable={isTab2Available}>
        <CountryPricingAndPayments country={country} />
      </TabPanel>
      <TabPanel value={currentTab} index={2} isAvailable={isTab3Available}>
        <CountryLocalSolutions country={country} />
      </TabPanel>
      <Blocks blocks={[services, counters]} />
    </>
  );
}
